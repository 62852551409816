<template>
  <div v-if="loggedIn">
    <v-row class="flex-grow-0">
      <v-col cols="6" md="4" lg="3">
        <tileCard
          icon="mdi-printer"
          color="green"
          @click="printDialog = true"
          v-ripple
        >
          Print Labels
        </tileCard>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <tileCard
          icon="mdi-cog"
          color="info"
          @click="settingsDialog = true"
          v-ripple
        >
          Settings
        </tileCard>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <tileCard
          icon="mdi-speedometer"
          color="purple lighten-1"
          @click="statsDialog = true"
          v-ripple
        >
          Stats
        </tileCard>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <tileCard
          icon="mdi-frequently-asked-questions"
          color="red"
          @click="faqDialog = true"
          v-ripple
        >
          Support
        </tileCard>
      </v-col>
    </v-row>
    <v-dialog v-model="faqDialog">
      <v-card style="background-color: #f1f1f1 !important">
        <v-container fluid>
          <faq />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="faqDialog = false" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="statsDialog" v-if="loggedIn">
      <v-card
        :style="`${statsDialogHeight} height: 100%; overflow-y: scroll; overflow-x: hidden; background-color: #f1f1f1 !important; position: relative;`"
        class="heightDialog"
      >
        <v-container fluid>
          <stats
            :internalHeight.sync="maxHeight"
            v-if="statsDialog"
            :dialogStatus="statsDialog"
          />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="statsDialog = false" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="settingsDialog" v-if="loggedIn">
      <v-card style="background-color: #f1f1f1 !important">
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="6">
              <tileCard :aspect-ratio="0">
                <template v-slot:content>
                  <div class="d-flex flex-column pa-3" style="text-align: left">
                    Click "Simulate Scan" to see the effect of this setting on
                    the message that appears when a QR code is scanned.<br />
                    <v-switch
                      class="ml-3"
                      :disabled="isChanging"
                      v-model="revealOnScan"
                      label="Reveal Part on Scan"
                    />
                    <small
                      >Each QR code has an index number that represents a piece
                      of the message. When a QR code is scanned, should that
                      piece of the message be shown, or just the index
                      number?</small
                    >
                    <br />
                    <center>
                      <v-btn
                        @click="
                          snackbarVisible = `${true}${new Date().getTime}`
                        "
                        color="info"
                        class="mb-1"
                        :disabled="isChanging"
                        ><v-progress-circular v-if="isChanging" indeterminate />
                        Simulate Scan</v-btn
                      >
                    </center>
                  </div>
                </template>
              </tileCard>
            </v-col>
            <v-col cols="12" md="6">
              <tileCard :aspect-ratio="0">
                <template v-slot:content>
                  <div class="d-flex flex-column pa-3" style="text-align: left">
                    Click "Simulate Scan" to see the effect of this setting on
                    the message that appears when a QR code is scanned.<br />
                    <v-switch
                      class="ml-3"
                      v-model="readLetter"
                      label="Read Partial Letter"
                      :disabled="isChanging"
                    />
                    <small
                      >Should a 'Read Letter' button appear? It will let the
                      user see all the parts of the message they have uncovered
                      so far. If not, they'll only be able to read the message
                      when it's 100% uncovered.</small
                    >
                    <br />
                    <center>
                      <v-btn
                        @click="
                          snackbarVisible = `${true}${new Date().getTime}`
                        "
                        color="info"
                        class="mb-1"
                        :disabled="isChanging"
                        ><v-progress-circular v-if="isChanging" indeterminate />
                        Simulate Scan</v-btn
                      >
                    </center>
                  </div>
                </template>
              </tileCard>
            </v-col>
          </v-row>
          <scan-snackbar
            v-model="snackbarVisible"
            :readLetter="readLetter"
            :revealOnScan="revealOnScan"
            :hasRevealed="false"
            :letterPortion="'Example Text from Message'"
            :letterIndex="1"
          />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="settingsDialog = false" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="printDialog" v-if="loggedIn">
      <v-card style="background-color: #f1f1f1 !important">
        <v-container fluid
          ><print-labels-component v-if="printDialog"
        /></v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="printDialog = false" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.heightDialog {
  transition-property: max-height, min-height;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
</style>

<script>
import tileCard from "@/components/tileCard.vue";
import scanSnackbar from "@/components/scanSnackbar.vue";
import stats from "@/components/stats.vue";
import faq from "@/views/HomeFAQ.vue";
import printLabelsComponent from "@/components/printLabelsComponent.vue";

export default {
  name: "ControlPanel",
  data() {
    return {
      loggedIn: false,
      snackbarVisible: false,
      isChanging: false,
      statsDialog: false,
      faqDialog: false,
      settingsDialog: false,
      printDialog: false,
      maxHeight: "48px",
    };
  },
  methods: {},
  computed: {
    statsDialogHeight() {
      return `max-height: min(90vh, calc(${this.maxHeight} + 76px)); min-height: min(90vh, calc(${this.maxHeight} + 76px));`;
    },
    userData() {
      let ud = this.$store.getters["awsRegistered/userData"];
      if (ud === false) {
        this.$store.dispatch("awsRegistered/getUserData");
        this.$store.commit("awsRegistered/updateGettingUserData", true);
        return true;
      } else {
        return ud;
      }
    },
    readLetter: {
      get() {
        let ud = this.userData;
        if (ud !== true) {
          return ud.readLetter;
        }
        return false;
      },
      async set(newVal) {
        this.isChanging = true;
        await this.$store.dispatch("awsRegistered/changeAttribute", {
          field: "readLetter",
          value: newVal ? "1" : "0",
        });
        this.isChanging = false;
      },
    },
    revealOnScan: {
      get() {
        let ud = this.userData;
        if (ud !== true) {
          return ud.revealOnScan;
        }
        return false;
      },
      async set(newVal) {
        this.isChanging = true;
        await this.$store.dispatch("awsRegistered/changeAttribute", {
          field: "revealOnScan",
          value: newVal ? "1" : "0",
        });
        this.isChanging = false;
      },
    },
    uncovered() {
      let data = this.$store.getters["awsRegistered/uncovered"];
      if (!data.loaded) {
        this.$store.commit("awsRegistered/loadingUncovered");
        this.$store.dispatch("awsRegistered/getUncovered", { need: true });
      }
      return {
        byIndex: data.byIndex,
        dates: data.dates,
      };
    },
    readLetterButton() {
      return (
        this.readLetter ||
        (Object.keys(this.uncovered.byIndex).length == this.letterDataLength &&
          this.letterDataLength)
      );
    },
    letterDataLength() {
      if (this.$store.getters["awsRegistered/letterLength"] < 0) {
        if (this.$store.getters["awsRegistered/letterLength"] == -2) {
          this.$store.commit("awsRegistered/updateLetterLength", -1);
          this.$store.dispatch("awsRegistered/getLetter");
        }
        return 0;
      } else {
        return this.$store.getters["awsRegistered/letterLength"];
      }
    },
  },
  async mounted() {
    let self = this;
    await Promise.all([
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve) => {
        if (!self.$store.hasModule("templateGenerator")) {
          let templateGenerator = (await import("@/store/templateGenerator.js"))
            .default;
          self.$store.registerModule("templateGenerator", templateGenerator);
        }
        resolve();
      }),
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve) => {
        if (!self.$store.hasModule("encryption")) {
          let encryption = (await import("@/store/encryption.js")).default;
          self.$store.registerModule("encryption", encryption);
        }
        resolve();
      }),
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve) => {
        if (!self.$store.hasModule("awsRegistered")) {
          let awsRegistered = (await import("@/store/awsRegistered.js"))
            .default;
          self.$store.registerModule("awsRegistered", awsRegistered);
        }
        resolve();
      }),
    ]);
    self.$store.commit("addLoading", "Logging In");
    let [unProto, pw] = self.$store.getters["util/unscramble"](
      self.$route.params.login
    );
    let un = self.$store.getters.formatUUID(unProto);
    await self.$store.dispatch("awsRegistered/updateLogin", { un, pw });
    self.$store.commit("removeLoading", "Logging In");
    self.loggedIn = true;
  },
  components: {
    tileCard,
    scanSnackbar,
    stats,
    faq,
    printLabelsComponent,
  },
};
</script>
